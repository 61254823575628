.box_grid_rent {
    background-color: #fff;
    display: block;
    position: relative;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .box_grid_rent .price {
    display: inline-block;
    font-weight: 500;
    color: #999;
  }
  .box_grid_rent .price strong {
    color: #32a067;
  }
  .box_grid_rent a.wish_bt {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 9px 10px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
  .box_grid_rent a.wish_bt:after {
    font-family: 'ElegantIcons';
    content: "\e030";
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .box_grid_rent a.wish_bt.liked:after {
    content: "\e089";
    color: #fc5b62;
  }
  .box_grid_rent a.wish_bt:hover.liked:after {
    color: #fc5b62;
  }
  .box_grid_rent a.wish_bt:hover:after {
    content: "\e089";
    color: #fff;
  }
  .box_grid_rent figure {
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    height: 210px;
  }
  .box_grid_rent figure small {
    position: absolute;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.6);
    left: 20px;
    top: 22px;
    text-transform: uppercase;
    color: #ccc;
    font-weight: 600;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 7px 10px 4px 10px;
    line-height: 1;
  }
  .box_grid_rent figure .read_more {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -12px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    text-align: center;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    z-index: 2;
  }
  .box_grid_rent figure .read_more span {
    background-color: #fcfcfc;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
    display: inline-block;
    color: #222;
    font-size: 12px;
    font-size: 0.75rem;
    padding: 5px 10px;
  }
  .box_grid_rent figure:hover .read_more {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .box_grid_rent figure a img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -moz-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    -o-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .box_grid_rent figure a:hover img {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  .box_grid_rent .wrapper {
    padding: 25px;
  }
  .box_grid_rent .wrapper h3 {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 0;
  }
  .box_grid_rent ul {
    padding: 20px 0px;
    border-top: 1px solid #ededed;
  }
  .box_grid_rent ul li {
    display: inline-block;
    margin-right: 15px;
  }
  .box_grid_rent ul li .score {
    margin-top: -10px;
  }
  .box_grid_rent ul li:last-child {
    margin-right: 0;
    float: right;
  }

ul, ol {
    list-style: none;
    margin: 0 0 5px 0;
    padding: 0;
}

.wrapperr {
    padding: 10px;
}

.figure{
    height:'50px' !important; 
    background-color:"#3b6998" !important;
}

.h3-p45{
    padding: '10px 0px 0px 45px' !important;
    color: '#FFFFFF' !important;
}

.h3-p35{
    padding: '10px 0px 0px 35px' !important;
    color: '#FFFFFF' !important;
}