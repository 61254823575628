  .Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Aligner-item {
    max-width: 50%;
  }
  
  .Aligner-item--top {
    align-self: flex-start;
  }
  
  .Aligner-item--bottom {
    align-self: flex-end;
  }

  /* @media (min-width: 800px) and (max-width: 850px) {
    .navbar:not(.top-nav-collapse) {
        background: #1C2331!important;
    }
  } */

  .view,body,html{
    height:100%
  }
  .navbar{
    background-color:rgba(0,0,0,.5)
  }  

  .navbar-dark .navbar-toggler{
    border-color: rgba(255, 255, 255, 255) !important;
    z-index: 9999;
  }

  .page-footer,.top-nav-collapse{
    background-color:#1C2331
  }

  @media(max-width :767px){
    .maincontainer{
      margin-top:4.1em;
    }
  
    .navbar{
      background-color: rgba(0,28,63,9.5);
    }
  }
  
  @media(max-width :575px){
    .maincontainer{
      margin-top:4.1em;
    }
    .navbar{
      background-color: rgba(0,28,63,9.5);
    }
  }

/*   
  @media(max-width:768px){
    .navbar{
   
      background-color:rgba(0,0,0,.2)
    }
  }
  
  @media(max-width :575px){
    .navbar{
   
      background-color:rgba(0,0,0,.2)
    }
  } */

  /* @media only screen and (max-width:768px){
    .navbar{background-color:#1C2331}
  } */